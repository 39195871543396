<template>
    <div v-if="formId" id="getresponse-form" class="px-10">
        <!-- prettier-ignore -->
        <component :is="'script'" v-if="scriptCode" type="text/javascript">
            (function (m, o, n, t, e, r, _) {
                m["__GetResponseAnalyticsObject"] = e;
                m[e] =
                    m[e] ||
                    function () {
                        (m[e].q = m[e].q || []).push(arguments);
                    };
                r = o.createElement(n);
                _ = o.getElementsByTagName(n)[0];
                r.async = 1;
                r.src = t;
                r.setAttribute("crossorigin", "use-credentials");
                _.parentNode.insertBefore(r, _);
            })(
                window,
                document,
                "script",
                "{{ scriptCode }}",
                "GrTracking"
            );
        </component>
        <component :is="'getresponse-form'" :form-id="formId" :e="e" />
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { Watch } from '@/utils/decorators';
import { extractor } from './GetResponse';

// allow GetResponse form custom element
Vue.config.ignoredElements = [
    ...Vue.config.ignoredElements,
    'getresponse-form'
];

const GetResponseProps = Vue.extend({
    name: 'GetResponse',
    props: {
        code: {
            type: String,
            default() {
                return '';
            }
        }
    }
});

@Component
export default class GetResponse extends GetResponseProps {
    @Watch('code')
    onCodeChange() {
        this.parse();
    }

    scriptCode = '';
    formId = '';
    e = '';

    mounted() {
        this.parse();
    }

    parse() {
        if (this.code) {
            const { scriptCode, formId, e } = extractor(this.code);

            if (scriptCode) {
                this.scriptCode = scriptCode;
            }

            if (formId) {
                this.formId = formId;
            }

            if (e) {
                this.e = e;
            }
        } else {
            this.reset();
        }
    }

    reset() {
        this.scriptCode = '';
        this.formId = '';
        this.e = '';
    }
}
</script>
