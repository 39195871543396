<template>
    <div>
        <google-form v-if="is('google')" :code="options?.crm?.google" />
        <hub-spot v-if="is('hubspot')" :code="options?.crm?.hubspot" />
        <get-response
            v-if="is('getresponse')"
            :code="options?.crm?.getresponse"
        />
    </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import GoogleForm from './GoogleForm.vue';
import HubSpot from './HubSpot.vue';
import GetResponse from './GetResponse.vue';

import type { ClientCabinOptions, CrmType } from '@/types/ClientCabin';

const CrmProviderProps = Vue.extend({
    name: 'CrmProvider',
    props: {
        options: {
            type: Object as PropType<ClientCabinOptions>,
            default() {
                return {
                    default_crm: null,
                    crm: {
                        google: '',
                        hubspot: '',
                        getresponse: ''
                    }
                };
            }
        }
    }
});

@Component({
    components: {
        GoogleForm,
        HubSpot,
        GetResponse
    }
})
export default class CrmProvider extends CrmProviderProps {
    is(provider: CrmType) {
        return this.options.default_crm === provider;
    }
}
</script>
