<template>
    <iframe
        ref="gform"
        :src="src"
        width="100%"
        :height="height"
        title="Let's connect!"
    >
        Loading...
    </iframe>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { Watch } from '@/utils/decorators';
import { extractor } from './GoogleForm';

const GoolgeFormProps = Vue.extend({
    name: 'GoogleForm',
    props: {
        code: {
            type: String,
            default() {
                return '';
            }
        }
    }
});

@Component
export default class GoogleForm extends GoolgeFormProps {
    $refs!: {
        gform: HTMLIFrameElement;
    };

    @Watch('code')
    onCodeChange() {
        this.parse();
    }

    src = '';
    width = '100%';
    height = 0;

    mounted() {
        this.parse();
    }

    parse() {
        if (this.code) {
            const { src, height } = extractor(this.code);

            if (src) {
                this.src = src;

                if (height) {
                    this.height = height;
                }
            }

            this.handleSumit();
        } else {
            this.reset();
        }
    }

    reset() {
        this.src = '';
        this.width = '100%';
        this.height = 0;
    }

    handleSumit() {
        let load = 0;

        this.$refs.gform.onload = () => {
            if (load) {
                this.$router.push('/proposal');
            }

            load++;
        };
    }
}
</script>

<style lang="scss" scoped>
iframe {
    border: none;
    margin: 0;
    padding: 0;
}
</style>
