<template>
    <div id="hubspot-form" class="px-10">
        <component
            :is="'script'"
            src="//js.hsforms.net/forms/embed/v2.js"
            type="text/javascript"
            charset="utf-8"
        />
        <!-- prettier-ignore -->
        <component :is="'script'" v-if="region">
            function whenAvailable(name, callback) {
                var interval = 10;
                window.setTimeout(function () {
                    if (window[name]) {
                        callback(window[name]);
                    } else {
                        whenAvailable(name, callback);
                    }
                }, interval);
            }

            try {
                whenAvailable('hbspt', function () {
                    hbspt.forms.create({
                        region: "{{ region }}",
                        portalId: "{{ portalId }}",
                        formId: "{{ formId }}",
                        target: "#hubspot-form"
                    });
                });
            } catch (e) {
                console.log(e);
            }
        </component>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { Watch } from '@/utils/decorators';
import { extractor } from './HubSpot';

const HubSpotProps = Vue.extend({
    name: 'HubSpot',
    props: {
        code: {
            type: String,
            default() {
                return '';
            }
        }
    }
});

@Component
export default class HubSpot extends HubSpotProps {
    @Watch('code')
    onCodeChange() {
        this.parse();
    }

    region = '';
    portalId = '';
    formId = '';

    mounted() {
        this.parse();
    }

    parse() {
        if (this.code) {
            const { region, portalId, formId } = extractor(this.code);

            if (region) {
                this.region = region;
            }

            if (portalId) {
                this.portalId = portalId;
            }

            if (formId) {
                this.formId = formId;
            }
        } else {
            this.reset();
        }
    }

    reset() {
        this.region = '';
        this.portalId = '';
        this.formId = '';
    }
}
</script>
